<template>
	<div class="input-wrapper d-flex align-items-center filter__number-range">
		<b-input
			type="number"
			:min="minData"
			:max="maxData"
			:step="stepData"
			v-model="valueData.from"
			ref="inputFrom"
			@input="handleRangeChange"
		></b-input>
		<span class="separator">-</span>
		<b-input
			type="number"
			:min="minData"
			:max="maxData"
			:step="stepData"
			v-model="valueData.to"
			ref="inputTo"
			@input="handleRangeChange"
		></b-input>
	</div>
</template>

<script>
import { debounce } from 'debounce';

export default {
	model: {
		prop: 'value',
		event: 'changed',
	},

	props: {
		value: { type: Object, default: () => ({ from: null, to: null }) },
		min: { type: Number, default: 0 },
		max: { type: Number, default: 10 },
		step: { type: Number, default: 1 },
	},
	data: function () {
		return {
			valueData: this.value ? { from: this.value.from, to: this.value.to } : { from: null, to: null },
			minData: this.min,
			maxData: this.max,
			stepData: this.step,
		};
	},
	methods: {
		handleRangeChange: debounce(function (e) {
			this.onChanged();
		}, 500),
		onChanged() {
			this.$emit('changed', this.valueData);
		},
	},
};
</script>

<style lang="scss" scoped>
.input-wrapper {
	.separator {
		margin: 0 2.5px;
	}
	> input {
		width: 45px;
	}
}
</style>
