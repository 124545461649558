<template>
	<b-card class="bcard-wrapper">
		<v-server-table :name="tableName" :columns="tableColumns" :options="tableOptions" :ref="tableName" class="page-table">
			<template #afterFilterWrapper>
				<div class="heading-wrapper">
					<div class="title-filters-wrapper">
						<slot name="header">
							<h4 v-if="headerTitle" class="mr-2 mb-3">{{ $t(headerTitle) }}</h4>
						</slot>

						<active-filters
							:filterDropdowns="filterDropdowns"
							:filters="filterModel"
							:filtersTranslations="columnsHeadings"
							@handleClick="removeFilters()"
						></active-filters>
					</div>

					<div class="d-flex flex-row align-items-end">
						<link-to-manual v-if="tooltipMsg" :tooltip="tooltipMsg"></link-to-manual>

						<megau-button
							v-if="addNewRecordMsg"
							classprop="btn btn-success dropdown-padding"
							icon="plus"
							titlePosition="right"
							:title="$t('addanewrecord')"
							@handleClick="$emit('addNewRecord', { detailId: 0 })"
						></megau-button>
					</div>
				</div>
			</template>

			<template v-if="showRowNumberColumn && !$slots.filter__NumberRow" #filter__NumberRow>
				<perpage-select :tableName="tableName"></perpage-select>
			</template>

			<template v-if="showRowNumberColumn && !$scopedSlots.NumberRow" #NumberRow="list">{{ list.index }}.</template>

			<template v-if="showActionsColumn && !$scopedSlots.Edit" #Edit="list">
				<div class="d-flex flex-row align-items-center justify-content-end">
					<slot name="rowActions" v-bind="list">
						<megau-button
							v-if="showActionEdit"
							classprop="btn btn-primary blue mr-1 ml-1"
							icon="edit"
							:title="$t('edit')"
							titlePosition="left"
							@handleClick="$emit('editRecord', list.row[modelId])"
						></megau-button>

						<slot name="customActions" v-bind="list"></slot>
					</slot>
				</div>
			</template>

			<template v-if="showActionsColumn && !$slots.filter__Edit" #filter__Edit>
				<div class="d-flex justify-content-end">
					<slot name="customEditActions"></slot>

					<megau-button
						classprop="btn btn-customdanger float-right color"
						icon="times-circle"
						:tooltip="$t('cancelallfilter')"
						@handleClick="removeFilters()"
					></megau-button>
				</div>
			</template>

			<template v-for="filter in columnsFilters" #[filter.slotName]>
				<component
					:is="filter.component.name"
					:key="`${filter.slotName}__${filter.model}`"
					v-bind="filter.component.props"
					v-on="filter.component.events"
					v-model="filterModelInternal[filter.model]"
				></component>
			</template>

			<template v-for="cellFormat in columnsCellFormat" #[cellFormat.slotName]="list">
				<template v-if="cellFormat.component">
					<component
						:is="cellFormat.component.name"
						:key="`${cellFormat.slotName}__${cellFormat.model}`"
						:[cellFormat.component.valueKey]="list.row[cellFormat.modelToDisplay]"
						v-bind="cellFormat.component.props(list)"
						v-on="cellFormat.component.events(list)"
					>
						<template v-if="cellFormat.component.valueKey === null">
							{{ list.row[cellFormat.model] }}
						</template>
					</component>
				</template>

				<template v-else>
					{{ list.row[cellFormat.modelToDisplay] }}
				</template>
			</template>

			<template v-for="(_, slot) in $scopedSlots" #[slot]="props">
				<slot :name="slot" v-bind="props" />
			</template>

			<template v-for="(_, slot) in $slots">
				<template :slot="slot">
					<slot :name="slot"></slot>
				</template>
			</template>
		</v-server-table>

		<slot name="afterTable"></slot>
	</b-card>
</template>

<script>
import serviceCommon from '@/services/service/common.service';
import vars from '@/services/helpers/variables';

import DateRange from '@/components/table/datepicker-range.filter';
import FilterAutocomplete from '@/components/common/filter-autocomplete';
import BooleanDisplay from '@/components/common/boolean-display';
import NumberRange from '@/components/table/number-range.filter';

import { prepareColumns } from './table-page/prepare-columns.service';

function returnEmptyObject() {
	return {};
}

export default {
	props: {
		tableName: { type: String, required: true },

		columns: { type: Array, required: true },
		filterModel: { type: Object, default: () => ({}) },
		requestFunction: { type: Function, required: true },
		showRowNumberColumn: { type: Boolean, default: true },
		showActionsColumn: { type: Boolean, default: true },
		showActionEdit: { type: Boolean, default: true },
		modelId: { type: String, default: 'id' },

		headerTitle: { type: String, default: '' },
		tooltipMsg: { type: String, default: null },
		addNewRecordMsg: { type: String, default: 'addanewrecord' },

		defaultOrderBy: { type: Object, default: () => ({ column: 'Id', ascending: 0 }) },

		customFilterDropdowns: { type: Object, default: () => ({}) },
		customHeadingTranslations: { type: Object, default: () => ({}) },
	},

	components: {
		DateRange,
		FilterAutocomplete,
		BooleanDisplay,
		NumberRange,
	},

	data() {
		return {
			filterModelInternal: {},
			filterDropdowns: {},
		};
	},

	computed: {
		preparedColumns() {
			return prepareColumns(this.columns);
		},

		tableOptions() {
			return {
				preserveState: true,
				orderBy: this.defaultOrderBy,
				filterByColumn: true,
				columnsDropdown: true,
				columns: 'Cols',
				pagination: vars.tableVars.paginationSetting,
				sortIcon: vars.tableVars.sortIcons,
				texts: serviceCommon.recordsPaginationsTexts(),
				hiddenColumns: this.hiddenColumns,
				sortable: this.sortableColumns,
				filterable: [],
				perPage: vars.tableVars.defaultPerPage,
				headings: this.columnsHeadings,
				requestFunction: this.requestFunction,
				columnsClasses: {
					NumberRow: 'vue-tables__number-row',
				},
			};
		},

		tableColumns() {
			let columns = this.columns.map((c) => c.model);

			if (this.showRowNumberColumn) {
				columns.unshift('NumberRow');
			}
			if (this.showActionsColumn) {
				columns.push('Edit');
			}

			return columns;
		},

		hiddenColumns() {
			return this.columns.filter((c) => c.hidden).map((c) => c.model);
		},

		sortableColumns() {
			return this.columns.filter((c) => c.sortable).map((c) => c.model);
		},

		columnsHeadings() {
			let standartHeadings = {
				NumberRow: this.$t('s.no.'),
				Edit: this.$t('actions'),
			};

			const headings = this.columns.reduce((headings, column) => {
				headings[column.model] = this.$t(column.i18n);
				return headings;
			}, standartHeadings);

			return {
				...headings,
				...this.customHeadingTranslations,
			};
		},

		columnsFilters() {
			return this.filterableColumns.map((c) => this.prepareColumnFilter(c));
		},

		columnsCellFormat() {
			return this.formatableColumns.map((c) => this.prepareColumnCellFormat(c));
		},

		filterableColumns() {
			return this.preparedColumns.filter((c) => !!c.filter);
		},

		formatableColumns() {
			return this.preparedColumns.filter((c) => !!c.cellFormat);
		},
	},

	watch: {
		filterModel: {
			immediate: true,
			handler(value) {
				this.filterModelInternal = value;
			},
		},

		customFilterDropdowns: {
			immediate: true,
			handler(value) {
				this.filterDropdowns = {
					...this.filterDropdowns,
					...value,
				};
			},
		},
	},

	created() {},

	mounted() {
		if (this.$store.state.quickFilter[this.tableName]) {
			this.filterModelInternal.QuickFilter = this.$store.state.quickFilter[this.tableName];
		}
		// this.$store.commit('DELETE_QUICK_FILTER', { filter: this.tableName });
	},

	methods: {
		handleFilter() {
			this.$emit('filterChanged', this.filterModelInternal);
		},

		removeFilters() {
			this.$emit('removeFilters', this.filterModelInternal);
		},

		prepareColumnFilter(column) {
			const filterData = {
				model: column.model,
				slotName: `filter__${column.model}`,
				component: this.filterComponent(column),
			};

			return filterData;
		},

		filterComponent(column) {
			this.fillFilterDropdown(column);

			return this.prepareFilterComponent(column);
		},

		fillFilterDropdown(column) {
			if (this.filterDropdowns[column.model] || !column.filterOptions || !column.filterOptions.dropdownOptions) {
				return;
			}

			this.filterDropdowns[column.model] = column.filterOptions.dropdownOptions;
		},

		prepareFilterComponent(column) {
			const filterOptions = column.filterOptions || {};

			switch (column.filter) {
				case 'text':
					return {
						name: 'b-form-input',
						props: { type: 'text', ...filterOptions.props },
						events: { change: this.handleFilter, ...filterOptions.events },
					};
				case 'country':
				case 'boolean':
				case 'dropdown':
					return {
						name: 'b-form-select',
						props: { options: this.filterDropdowns[column.model] || [], ...filterOptions.props },
						events: { change: this.handleFilter, ...filterOptions.events },
					};
				case 'daterange':
					return {
						name: 'date-range',
						props: filterOptions.props,
						events: { handleUpdateDates: this.handleFilter, ...filterOptions.events },
					};
				case 'autocomplete':
					return {
						name: 'filter-autocomplete',
						props: { initValue: this.filterModelInternal[column.model] || '', ...filterOptions.props },
						events: { onSelect: this.handleFilter, ...filterOptions.events },
					};
				case 'numberrange':
					return {
						name: 'number-range',
						props: { ...filterOptions.props },
						events: { changed: this.handleFilter, ...filterOptions.events },
					};
				default:
					return {
						name: column.filter,
						props: filterOptions.props || {},
						events: filterOptions.events || {},
					};
			}
		},

		prepareColumnCellFormat(column) {
			const cellFormatData = {
				slotName: column.model,
				model: column.model,
				modelToDisplay: (column.cellFormatOptions || {}).modelToDisplay || column.model,
			};

			if (column.cellFormat === 'modelToDisplay') {
				// nothing to change
			} else {
				cellFormatData.component = this.prepareCellFormatComponent(column);
			}

			return cellFormatData;
		},

		prepareCellFormatComponent(column) {
			const cellFormatOptions = column.cellFormatOptions || {};

			const component = {
				valueKey: null,
				props: cellFormatOptions.props || returnEmptyObject,
				events: cellFormatOptions.events || returnEmptyObject,
			};

			switch (column.cellFormat) {
				case 'country':
					component.name = 'country-flag';
					component.valueKey = 'countryId';
					return component;
				case 'boolean':
					component.name = 'boolean-display';
					component.valueKey = 'boolValue';
					return component;
				case 'link':
					component.name = 'a';
					return component;
			}
		},

		refresh() {
			this.$refs[this.tableName].getData();
		},
	},
};
</script>

<style lang="scss">
.vue-tables__number-row {
	min-width: 55px;
}
</style>