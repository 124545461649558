<template>
	<date-range-picker
		:singleDatePicker="singleDatePicker"
		v-model="dateRange"
		:locale-data="locale"
		:opens="opens"
		:ranges="false"
		:autoApply="autoApply"
		:showDropdowns="true"
		control-container-class="daterangepicker__control-container"
	></date-range-picker>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker';
import moment from 'moment';

moment.locale('sk');

export default {
	components: { DateRangePicker },

	model: {
		prop: 'dateRangeProp',
		event: 'handleUpdateDates',
	},

	props: {
		dateRangeProp: { type: Object },
	},

	computed: {
		dateRange: {
			get() {
				return this.dateRangeProp ? this.dateRangeProp : { startDate: null, endDate: null };
			},
			set(newValue) {
				this.$emit('handleUpdateDates', newValue);
			},
		},
	},

	data() {
		return {
			singleDatePicker: false,
			opens: 'right', //which way the picker opens, default "center", can be "left"/"right"
			autoApply: true,
			locale: {
				format: this.$store.state.lang.DateFormat, //fomart of the dates displayed
				applyLabel: this.$i18n.t('delete2315'),
				cancelLabel: this.$i18n.t('cancel2314'),
				separator: ' - ', //separator between the two ranges
				daysOfWeek: moment.weekdaysMin(), //array of days - see moment documenations for details
				firstDay: 1, //ISO first day of week - see moment documenations for details
			},
		};
	},
};
</script>

<style lang="scss" scoped>
@import '~vue2-daterange-picker/dist/lib/vue-daterange-picker.min.css';
.vue-daterange-picker {
	width: 100%;
	white-space: nowrap;
	.form-control.reportrange-text {
		padding-left: 3.5px;
	}
}
</style>

<style lang="scss">
.daterangepicker .calendar-table td,
.daterangepicker .calendar-table th {
	line-height: 15px;
}
.daterangepicker.dropdown-menu {
	.calendars.row {
		flex-wrap: nowrap !important;
	}
}

.vue-daterange-picker > .reportrange-text {
	overflow: hidden;
	text-overflow: ellipsis;
}
</style>