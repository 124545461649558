var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "input-wrapper d-flex align-items-center filter__number-range",
    },
    [
      _c("b-input", {
        ref: "inputFrom",
        attrs: {
          type: "number",
          min: _vm.minData,
          max: _vm.maxData,
          step: _vm.stepData,
        },
        on: { input: _vm.handleRangeChange },
        model: {
          value: _vm.valueData.from,
          callback: function ($$v) {
            _vm.$set(_vm.valueData, "from", $$v)
          },
          expression: "valueData.from",
        },
      }),
      _c("span", { staticClass: "separator" }, [_vm._v("-")]),
      _c("b-input", {
        ref: "inputTo",
        attrs: {
          type: "number",
          min: _vm.minData,
          max: _vm.maxData,
          step: _vm.stepData,
        },
        on: { input: _vm.handleRangeChange },
        model: {
          value: _vm.valueData.to,
          callback: function ($$v) {
            _vm.$set(_vm.valueData, "to", $$v)
          },
          expression: "valueData.to",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }