import store from '@/store';
import serviceEnums from '@/services/service/enums.service';

export function prepareColumns(columns) {
	let _cols = columns.map((c) => {
		fillFilter(c);
		fillCellFormat(c);
		return c;
	});

	return _cols;
}

function fillFilter(column) {
	if (column.filter) {
		const method = filters[column.filter] || filters.text;
		method(column);
		return;
	}

	if (column.colType) {
		const method = filters[column.colType] || filters.text;
		method(column);
		return;
	}
}

function fillCellFormat(column) {
	if (column.cellFormat && cellFormats[column.cellFormat]) {
		cellFormats[column.cellFormat](column);
		return;
	}

	if (column.colType && cellFormats[column.colType]) {
		cellFormats[column.colType](column);
		return;
	}
}

const filters = {
	dropdown: (column) => {
		column.filter = 'dropdown';
		column.filterOptions = {
			dropdownOptions: [],
			...column.filterOptions,
		};
	},

	country: (column) => {
		column.filter = 'country';
		column.filterOptions = {
			dropdownOptions: store.getters.countryForDropdown,
			...column.filterOptions,
		};
	},

	boolean: (column) => {
		column.filter = 'boolean';
		column.filterOptions = {
			dropdownOptions: serviceEnums.getEnumForDropdown('yesNo', true),
			...column.filterOptions,
		};
	},

	daterange: (column) => {
		column.filter = 'daterange';
		column.filterOptions = {
			...column.filterOptions,
		};
	},

	link: (column) => {
		column.filter = 'text';
		column.filterOptions = { ...column.filterOptions };
	},

	text: (column) => {
		column.filter = 'text';
		column.filterOptions = { ...column.filterOptions };
	},

	numberrange: (column) => {
		column.filter = 'numberrange';
		column.filterOptions = { ...column.filterOptions };
	},

	autocomplete: (column) => {
		column.filter = 'autocomplete';
		column.filterOptions = { ...column.filterOptions };
	},
};

const cellFormats = {
	dropdown: (column) => {
		column.cellFormat = 'country';
		column.cellFormatOptions = {
			...column.cellFormatOptions,
		};
	},

	country: (column) => {
		column.cellFormat = 'country';
		column.cellFormatOptions = {
			...column.cellFormatOptions,
		};
	},

	boolean: (column) => {
		column.cellFormat = 'boolean';
		column.cellFormatOptions = {
			...column.cellFormatOptions,
		};
	},

	link: (column) => {
		column.cellFormat = 'link';
		column.cellFormatOptions = {
			...column.cellFormatOptions,
		};
	},
};
