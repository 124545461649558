import { cloneDeep, isEmpty } from 'lodash';
import store from '@/store';
import serviceCommon from '@/services/service/common.service';
import loading from '@/mixins/loading.mixin';
import countryGlobalFilter from '@/mixins/country-global-filter.mixin';

function getPreviousTableNameIfDifferent(tablePageName) {
	const previus = window.activeTablePageName;
	window.activeTablePageName = tablePageName;

	if (!previus) {
		return null;
	}

	if (previus === tablePageName) {
		return null;
	}

	return previus;
}

export const tablePageMixin = {
	mixins: [loading, countryGlobalFilter],

	data() {
		return {
			controllerName: '',
			columns: [],
			filterModel: {},
			defaultFilters: {},
			useQuickFilter: false,
			quickFilterUnsubscribe: null,
		};
	},

	watch: {
		columns: {
			immediate: true,
			handler(value) {
				this.initFilterModel();
			},
		},
	},

	created() {
		const previusTableName = getPreviousTableNameIfDifferent(this.$options.name);
		if (previusTableName && this.$store.hasModule(previusTableName)) {
			this.$store.unregisterModule(previusTableName);

			if (this.$store.state.quickFilter[previusTableName]) {
				this.$store.commit('DELETE_QUICK_FILTER', { filter: previusTableName });
			}
		}
	},

	mounted() {
		if (this.useQuickFilter) {
			this.quickFilterUnsubscribe = this.$store.subscribe((mutation, state) => {
				if (this.$options.name && mutation.type === 'SET_QUICK_FILTER' && mutation.payload.filter == this.$options.name) {
					this.tableGetData();
				}
			});
		}
	},

	destroyed() {
		if (this.quickFilterUnsubscribe) {
			this.quickFilterUnsubscribe();
		}
	},

	methods: {
		redirectToDetail(id = 0) {
			throw 'Not implemented method';
		},

		requestFunction(data) {
			this.startLoading(false);
			let cdata = cloneDeep(data);

			const QuickFilter = store.state.quickFilter[this.$options.name];

			if (this.useQuickFilter && QuickFilter) {
				cdata.filter = { QuickFilter };
				this.filterModel = cdata.filter;
			} else if (this.globalCountryId > 0) {
				if (cdata.filter) {
					cdata.filter.CountryId = this.globalCountryId;
				} else {
					cdata.filter = { CountryId: this.globalCountryId };
				}
			}

			const info = {
				data: this.transformRequestData(this.requestDefaultFilters(cdata)),
				controller: this.controllerName,
				tableName: this.$options.name,
				onLoaded: () => {
					this.stopLoading();
				},
			};

			return serviceCommon.getListForTable(info).then((res) => this.transformResponseData(res));
		},

		requestDefaultFilters(data) {
			if (data.filter === undefined) {
				data.filter = this.defaultFilters;
			}

			return data;
		},

		transformRequestData(data) {
			return data;
		},

		transformResponseData(data) {
			return data;
		},

		handleFilter(filters) {
			this.filterModel = {
				...this.filterModel,
				...filters,
			};
			serviceCommon.setCustomFilter(this.filterModel, this.$options.name);
			this.onFilterChanged();
		},

		removeAllFilters(filters) {
			this.filterModel = {
				...this.filterModel,
				...filters,
			};
			this.globalCountryId = null;
			serviceCommon.removeCustomFilter(this.filterModel, this.$options.name);
			this.onFilterChanged();
		},

		onFilterChanged() {},

		tableGetData() {
			this.$refs[this.$options.name].refresh();
		},

		initFilterModel() {
			const storedFilters = serviceCommon.getCustomFilter(this.$options.name);

			if (!this.filterModel.CountryId && !isEmpty(storedFilters) && storedFilters.CountryId) {
				this.globalCountryId = null;
			}

			this.filterModel = isEmpty(storedFilters) ? initFreshFilters(this.columns.filter((c) => !!c.filter)) : storedFilters;

			this.defaultFilters = { ...this.filterModel };

			function initFreshFilters(columns) {
				return columns.reduce((fm, c) => {
					fm[c.model] = c.filterInitValue === undefined ? null : c.filterInitValue;
					return fm;
				}, {});
			}
		},
	},
};
