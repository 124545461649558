var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("date-range-picker", {
    attrs: {
      singleDatePicker: _vm.singleDatePicker,
      "locale-data": _vm.locale,
      opens: _vm.opens,
      ranges: false,
      autoApply: _vm.autoApply,
      showDropdowns: true,
      "control-container-class": "daterangepicker__control-container",
    },
    model: {
      value: _vm.dateRange,
      callback: function ($$v) {
        _vm.dateRange = $$v
      },
      expression: "dateRange",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }