var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card",
    { staticClass: "bcard-wrapper" },
    [
      _c(
        "v-server-table",
        {
          ref: _vm.tableName,
          staticClass: "page-table",
          attrs: {
            name: _vm.tableName,
            columns: _vm.tableColumns,
            options: _vm.tableOptions,
          },
          scopedSlots: _vm._u(
            [
              {
                key: "afterFilterWrapper",
                fn: function () {
                  return [
                    _c("div", { staticClass: "heading-wrapper" }, [
                      _c(
                        "div",
                        { staticClass: "title-filters-wrapper" },
                        [
                          _vm._t("header", function () {
                            return [
                              _vm.headerTitle
                                ? _c("h4", { staticClass: "mr-2 mb-3" }, [
                                    _vm._v(_vm._s(_vm.$t(_vm.headerTitle))),
                                  ])
                                : _vm._e(),
                            ]
                          }),
                          _c("active-filters", {
                            attrs: {
                              filterDropdowns: _vm.filterDropdowns,
                              filters: _vm.filterModel,
                              filtersTranslations: _vm.columnsHeadings,
                            },
                            on: {
                              handleClick: function ($event) {
                                return _vm.removeFilters()
                              },
                            },
                          }),
                        ],
                        2
                      ),
                      _c(
                        "div",
                        { staticClass: "d-flex flex-row align-items-end" },
                        [
                          _vm.tooltipMsg
                            ? _c("link-to-manual", {
                                attrs: { tooltip: _vm.tooltipMsg },
                              })
                            : _vm._e(),
                          _vm.addNewRecordMsg
                            ? _c("megau-button", {
                                attrs: {
                                  classprop: "btn btn-success dropdown-padding",
                                  icon: "plus",
                                  titlePosition: "right",
                                  title: _vm.$t("addanewrecord"),
                                },
                                on: {
                                  handleClick: function ($event) {
                                    return _vm.$emit("addNewRecord", {
                                      detailId: 0,
                                    })
                                  },
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]),
                  ]
                },
                proxy: true,
              },
              _vm.showRowNumberColumn && !_vm.$slots.filter__NumberRow
                ? {
                    key: "filter__NumberRow",
                    fn: function () {
                      return [
                        _c("perpage-select", {
                          attrs: { tableName: _vm.tableName },
                        }),
                      ]
                    },
                    proxy: true,
                  }
                : null,
              _vm.showRowNumberColumn && !_vm.$scopedSlots.NumberRow
                ? {
                    key: "NumberRow",
                    fn: function (list) {
                      return [_vm._v(_vm._s(list.index) + ".")]
                    },
                  }
                : null,
              _vm.showActionsColumn && !_vm.$scopedSlots.Edit
                ? {
                    key: "Edit",
                    fn: function (list) {
                      return [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex flex-row align-items-center justify-content-end",
                          },
                          [
                            _vm._t(
                              "rowActions",
                              function () {
                                return [
                                  _vm.showActionEdit
                                    ? _c("megau-button", {
                                        attrs: {
                                          classprop:
                                            "btn btn-primary blue mr-1 ml-1",
                                          icon: "edit",
                                          title: _vm.$t("edit"),
                                          titlePosition: "left",
                                        },
                                        on: {
                                          handleClick: function ($event) {
                                            return _vm.$emit(
                                              "editRecord",
                                              list.row[_vm.modelId]
                                            )
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                  _vm._t("customActions", null, null, list),
                                ]
                              },
                              null,
                              list
                            ),
                          ],
                          2
                        ),
                      ]
                    },
                  }
                : null,
              _vm.showActionsColumn && !_vm.$slots.filter__Edit
                ? {
                    key: "filter__Edit",
                    fn: function () {
                      return [
                        _c(
                          "div",
                          { staticClass: "d-flex justify-content-end" },
                          [
                            _vm._t("customEditActions"),
                            _c("megau-button", {
                              attrs: {
                                classprop:
                                  "btn btn-customdanger float-right color",
                                icon: "times-circle",
                                tooltip: _vm.$t("cancelallfilter"),
                              },
                              on: {
                                handleClick: function ($event) {
                                  return _vm.removeFilters()
                                },
                              },
                            }),
                          ],
                          2
                        ),
                      ]
                    },
                    proxy: true,
                  }
                : null,
              _vm._l(_vm.columnsFilters, function (filter) {
                return {
                  key: filter.slotName,
                  fn: function () {
                    return [
                      _c(
                        filter.component.name,
                        _vm._g(
                          _vm._b(
                            {
                              key: filter.slotName + "__" + filter.model,
                              tag: "component",
                              model: {
                                value: _vm.filterModelInternal[filter.model],
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.filterModelInternal,
                                    filter.model,
                                    $$v
                                  )
                                },
                                expression: "filterModelInternal[filter.model]",
                              },
                            },
                            "component",
                            filter.component.props,
                            false
                          ),
                          filter.component.events
                        )
                      ),
                    ]
                  },
                  proxy: true,
                }
              }),
              _vm._l(_vm.columnsCellFormat, function (cellFormat) {
                return {
                  key: cellFormat.slotName,
                  fn: function (list) {
                    return [
                      cellFormat.component
                        ? [
                            _c(
                              cellFormat.component.name,
                              _vm._g(
                                _vm._b(
                                  _vm._b(
                                    {
                                      key:
                                        cellFormat.slotName +
                                        "__" +
                                        cellFormat.model,
                                      tag: "component",
                                    },
                                    "component",
                                    _vm._d({}, [
                                      cellFormat.component.valueKey,
                                      list.row[cellFormat.modelToDisplay],
                                    ])
                                  ),
                                  "component",
                                  cellFormat.component.props(list),
                                  false
                                ),
                                cellFormat.component.events(list)
                              ),
                              [
                                cellFormat.component.valueKey === null
                                  ? [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t" +
                                          _vm._s(list.row[cellFormat.model]) +
                                          "\n\t\t\t\t\t"
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ]
                        : [
                            _vm._v(
                              "\n\t\t\t\t" +
                                _vm._s(list.row[cellFormat.modelToDisplay]) +
                                "\n\t\t\t"
                            ),
                          ],
                    ]
                  },
                }
              }),
              _vm._l(_vm.$scopedSlots, function (_, slot) {
                return {
                  key: slot,
                  fn: function (props) {
                    return [_vm._t(slot, null, null, props)]
                  },
                }
              }),
            ],
            null,
            true
          ),
        },
        [
          _vm._l(_vm.$slots, function (_, slot) {
            return [_c("template", { slot: slot }, [_vm._t(slot)], 2)]
          }),
        ],
        2
      ),
      _vm._t("afterTable"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }